:root {
  /* Base colors */
  --color--blue: #1374e2;
  --color--blue--hover: #1666c5;
  --color--blue--light: #c3ddfb;
  --color--blue--dark: hsl(213, 80%, 43%);

  --color--brown: #652f00;
  --color--orange: #ffae28;
  --color--orange--dark: #ffae28;

  --color--red: #ee000f;
  --color--error: #e81c27;
  --color--success: #057f11;
  --color--success--light: rgb(5, 127, 17, 0.4);

  --primary-gradient: var(--color--orange)
    linear-gradient(
      180deg,
      var(--color--orange) 0%,
      #ffcd1d 6.96%,
      var(--color--orange--dark) 100%
    );

  /* Text */
  --color--text--base: #242c40;
  --color--text--secondary: #6a7390;
  --color--text--disabled: #rgba(0, 0, 0, 0.06);

  /* Shades */
  --color--input-background: #fafafa;
  --color--input-border: rgba(110, 117, 140, 0.1);
  --color--background--hover: rgba(158, 158, 158, 0.2);
  --color--background--grey: #dfdfdf;
  --color--background--blue: #98cbff;
  --color--background--red: #ffe3e8;
  --color--background--red-hover: #ffc9d3;
  --color--background--grey-blue: #bdcde0;
  --color--background--light-blue: #e7f1fc;
  --color--background--light-green: #f2fbec;
  --color--background--light-grey: #f0f1f3;
  --color--background--dark: #40444f;
  --color--background--dark--01: rgb(64, 68, 79, 0.1);
  --color--background--dark--095: rgb(64, 68, 79, 0.95);
  --color--button-hover: rgba(158, 158, 158, 0.2);
  --color--divider: rgba(110, 117, 140, 0.3);
  --color--divider--light: #ecedef;
  --color--divider--lighter: #f0f0f1;
  --color--hint: #757575;
  --color--icon: #6e758c;
}
